import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Form,
  Icon,
} from 'semantic-ui-react';
import { withCustomRouter } from 'HOC';
import {
  getError,
  resetValidationTerrorist,
  setCurrentAgentType,
  changeAgentFieldValue,
  validateAgentFieldValues,
  setAgentTouchField,
  changeInsuredIsSame,
  setAgentAllTouchFields,
  setStageProduct,
  setAddressDataAgent,
  setInsuredValues,
} from 'redux/rootActions';
import { getBlackAddressInfo } from 'redux/rootSelectors';
import { memoize } from 'lodash';
import {
  checkSnils,
  setFieldValues,
  setSomeFieldsValues,
  getAddress,
  getBankList,
  getFmsUnitList,
} from 'helpers';
import {
  LANG_DICTIONARY,
  INSURED_FIELDS_LIVING,
  CHILD_INSURED_FIELDS_LIVING,
  BLOCK_NAMES,
  INPUT_NAMES,
  INS_LINE_TYPES,
} from 'consts';
import {
  DocumentBlock,
  AddressBlockCommon,
  ContactBlock,
  FormSection,
  InnSnilsBlock,
  BankBlock,
  PersonBlock,
  RelationShipBlock,
  RequiredLabel,
  ErrorMessage,
  CustomCheckBox,
} from 'components';
import { Collapse } from 'reactstrap';
import './styles.scss';
import { simpleDate } from '../../../helpers';

const defaultProps = {
  isLoading: false,
  history: null,
  insuredIsSame: true,
  error: null,
  snilsError: null,
  GetError: null,
  stage: 1,
  hideInn: true,
  hideSnils: true,
  personBlockProps: {},
  documentBlockProps: {},
  addressBlockProps: {},
  bankBlockProps: {},
  addressLivingBlockProps: {},
  insSnilsBlockProps: {},
  contactBlockProps: {},
  relationBlockProps: {},
  changeFieldValue: () => null,
  setTouchField: () => null,
  isInsured: false,
  isChildForm: false,
  validate: () => null,
  isChild: false,
  setInsuredIsSame: () => null,
  hideBankInfo: false,
  scenario: '',
  disableInsuredIsSame: false,
  checkPassportCovid: false,
  insurerIsInsuredSetting: false,
  isListBlackAddress: false,
  onChangeAddress: () => null,
  clientTestRecordFields: {},
};

const propTypes = {
  checkNumbers: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  insuredIsSame: PropTypes.bool,
  error: PropTypes.string,
  snilsError: PropTypes.string,
  GetError: PropTypes.func,
  stage: PropTypes.number,
  hideInn: PropTypes.bool,
  hideSnils: PropTypes.bool,
  personBlockProps: PropTypes.object,
  documentBlockProps: PropTypes.object,
  addressBlockProps: PropTypes.object,
  bankBlockProps: PropTypes.object,
  addressLivingBlockProps: PropTypes.object,
  insSnilsBlockProps: PropTypes.object,
  contactBlockProps: PropTypes.object,
  relationBlockProps: PropTypes.object,
  changeFieldValue: PropTypes.func,
  setTouchField: PropTypes.func,
  match: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isInsured: PropTypes.bool,
  isChildForm: PropTypes.bool,
  validate: PropTypes.func,
  setInsuredIsSame: PropTypes.func,
  isChild: PropTypes.bool,
  hideBankInfo: PropTypes.bool,
  scenario: PropTypes.string,
  disableInsuredIsSame: PropTypes.bool,
  checkPassportCovid: PropTypes.bool,
  insurerIsInsuredSetting: PropTypes.bool,
  isListBlackAddress: PropTypes.bool,
  insurerDataUrl: PropTypes.string.isRequired,
  preliminaryCalculationUrl: PropTypes.string.isRequired,
  onChangeAddress: PropTypes.func,
  clientTestRecordFields: PropTypes.object,
};

const b = block('agent-data');
const formContainer = block('formContainer');
const {
  DOCUMENTS,
  REGISTRATION_ADDRESS,
  LIVING_ADDRESS,
  SAME_ADDRESS,
  IS_SAME,
  CONTACT_DATA,
  BANK_DETAILS,
  DEFAULT_CONTRY_RUSSIA,
  DOCUMENTS_CHILD,
  REGISTRATION_ADDRESS_CHILD,
  LIVING_ADDRESS_CHILD,
  CONTACT_DATA_CHILD,
  INSURER_AND_INSURED_CANNOT_MATCH_AS_THERE_ARE_DIFFERENT_GENERAL_OR_DATE_OF_BIRTH,
  SINGULAR_INSURED,
  SINGULAR_INSURED_CHILD,
  DOCUMENT_TYPE_BIRTH_DOCUMENT,
} = LANG_DICTIONARY;

const INSURES_INITIAL_LIST = [
  { field: 'countryLiving', value: DEFAULT_CONTRY_RUSSIA },
  { field: 'childcountryLiving', value: DEFAULT_CONTRY_RUSSIA },
];

export default (typeForm) => {
  class AgentData extends Component {
    constructor(props) {
      super(props);
      const {
        stage,
        history,
        isInsured,
        match: { params: { id } },
        config,
        insurerDataUrl,
        preliminaryCalculationUrl,
      } = props;
      const minStage = isInsured ? 3 : 2;
      const redirectPath = isInsured ? insurerDataUrl : preliminaryCalculationUrl;
      this.state = {
        ...config,
        ageError: null,
        disableFIO: false,
        isDisableMobile: false,
      };

      if (stage < minStage) {
        history.push(`${redirectPath}/${id}`);
      }
    }

    componentDidMount() {
      const {
        changeFieldValue,
        clientTestRecordFields,
      } = this.props;

      changeFieldValue({
        name: 'firstName',
        value: clientTestRecordFields.firstName,
        blockName: BLOCK_NAMES.personBlock,
        form: typeForm,
      });

      changeFieldValue({
        name: 'lastName',
        value: clientTestRecordFields.lastName,
        blockName: BLOCK_NAMES.personBlock,
        form: typeForm,
      });

      changeFieldValue({
        name: 'patronymic',
        value: clientTestRecordFields.middleName,
        blockName: BLOCK_NAMES.personBlock,
        form: typeForm,
      });

      changeFieldValue({
        name: 'mobilePhone',
        value: clientTestRecordFields.mobileNumber,
        blockName: BLOCK_NAMES.contactBlock,
        form: typeForm,
      });

      this.state.disableFIO =
      Boolean(clientTestRecordFields.firstName && clientTestRecordFields.lastName);
      this.state.isDisableMobile = Boolean(clientTestRecordFields.mobileNumber);
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutInsuredIsSame);
    }

    renderAddressCheckbox = ({
      onChange,
      onBlur,
      setFieldValue,
    },
    prefix = '') => {
      const {
        addressLivingBlockProps: {
          values: {
            livingAddressIsSame,
          },
        },
      } = this.props;

      return (
        <Form.Field className={b('addressCheckboxWrap').toString()}>
          <CustomCheckBox
            name={`${prefix}livingAddressIsSame`}
            className={b('addressCheckbox')}
            checked={livingAddressIsSame}
            onChange={(name, checked) => {
              setFieldValues(
                prefix ? CHILD_INSURED_FIELDS_LIVING : INSURED_FIELDS_LIVING,
                setFieldValue,
                INSURES_INITIAL_LIST,
              );

              onChange({
                target: {
                  value: !checked,
                  name,
                },
              });
            }}
            onBlur={onBlur}
          />
          <p>{SAME_ADDRESS}</p>
        </Form.Field>
      );
    };

    setFmsUnit = (values) => {
      const fmsUnitList = getFmsUnitList(values);
      const actionSetFieldValue = this.customSetFieldValue(BLOCK_NAMES.documentBlock);
      setSomeFieldsValues(fmsUnitList, actionSetFieldValue);
    };

    handleChangeAddress = (values, isLiving) => {
      const { onChangeAddress } = this.props;
      const currentBlock = isLiving ? BLOCK_NAMES.addressBlockLiving : BLOCK_NAMES.addressBlock;
      onChangeAddress(values, typeForm, currentBlock);
    }

    setAddress = (isLiving) => (values) => {
      const currentBlock = isLiving ? BLOCK_NAMES.addressBlockLiving : BLOCK_NAMES.addressBlock;
      const actionSetFieldValue = this.customSetFieldValue(currentBlock);
      const addressList = getAddress(values, isLiving);
      setSomeFieldsValues(addressList, actionSetFieldValue);
    };

    setBankValues = (values) => {
      const bankList = getBankList(values);
      const actionSetFieldValue = this.customSetFieldValue(BLOCK_NAMES.bankBlock);
      setSomeFieldsValues(bankList, actionSetFieldValue);
    };

    validateSnils = (value) => {
      const { snilsError, GetError } = this.props;
      const { result, error } = checkSnils(value);

      if (snilsError !== error) GetError(error, 'snilsError');

      return result;
    };

    isShow = () => {
      const {
        isInsured,
        insuredIsSame,
        isChildForm,
        insurerIsInsuredSetting,
      } = this.props;

      if (!isInsured || isChildForm) return true;

      return !insurerIsInsuredSetting && !insuredIsSame;
    };

    handleChangeStore = (blockName) => (event) => {
      const { changeFieldValue, validate } = this.props;
      const { target } = event;
      const { name, value } = target;

      if (blockName === BLOCK_NAMES.contactBlock && name.endsWith(INPUT_NAMES.mobilePhone)) {
        if (value.trim()[3] !== '9' || value.trim()[3] === '_') {
          changeFieldValue({
            name,
            value: `+7(${value.trim().slice(4)}`,
            blockName,
            form: typeForm,
          });
          setTimeout(() => {
            target.setSelectionRange(3, 3);
          }, 0);

          return;
        }
      }

      changeFieldValue({
        name,
        value,
        blockName,
        form: typeForm,
      });
      validate({ newValue: { name, value }, form: typeForm });
    };

    // change field by programmatically
    customSetFieldValue = (blockName, isValidate = true) => (name, value) => {
      const { changeFieldValue, validate } = this.props;

      if (blockName === BLOCK_NAMES.documentBlock && name.endsWith(INPUT_NAMES.issuedBy)) {
        changeFieldValue({
          name: 'issuedByAccepted',
          value: false,
          blockName,
          form: typeForm,
        });
      }

      changeFieldValue({
        name,
        value,
        blockName,
        form: typeForm,
      });

      if (isValidate) {
        validate({ newValue: { name, value }, form: typeForm });
      }
    };

    // change field by hand
    customSetFieldTouch = (blockName) => (name, value) => {
      const { setTouchField, changeFieldValue, validate } = this.props;

      setTouchField({
        name,
        value,
        blockName,
        form: typeForm,
      });

      if (blockName === BLOCK_NAMES.documentBlock && name.endsWith(INPUT_NAMES.issuedBy)) {
        changeFieldValue({
          name: 'issuedByAccepted',
          value: false,
          blockName,
          form: typeForm,
        });
        // validate on next tick
        setTimeout(() => validate({ newValue: { name, value }, form: typeForm }));
      }
    };

    handleBlur = (blockName) => ({ target: { name, value } }) => {
      const { setTouchField, validate } = this.props;
      setTouchField({
        name,
        value: true,
        blockName,
        form: typeForm,
      });
      validate({ newValue: { name, value }, form: typeForm });
    };

    customMemoize = (func) => memoize(func, (data) => JSON.stringify(data));

    getInputProps = ((data, blockName) => {
      const { isChildForm } = this.props;

      return {
        ...data,
        onChange: this.handleChangeStore(blockName),
        onBlur: this.handleBlur(blockName),
        setFieldTouched: this.customSetFieldTouch(blockName),
        setFieldValue: this.customSetFieldValue(blockName),
        prefixScroll: isChildForm ? 'child' : '',
      };
    });

    getInputPropsPerson = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.personBlock),
    );

    getInputPropsDocuments = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.documentBlock),
    );

    getInputPropsAddress = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.addressBlock),
    );

    getInputPropsBank = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.bankBlock),
    );

    getInputPropsAddressLiving = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.addressBlockLiving),
    );

    getInputPropsInnSnils = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.innSnilsBlock),
    );

    getInputPropsContact = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.contactBlock),
    );

    getInputPropsRelation = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.relationBlock),
    );

    handleClickInsuredIsSame = () => {
      const { disableInsuredIsSame } = this.props;

      if (disableInsuredIsSame) {
        this.setState({
          errorInsuredIsSame:
            INSURER_AND_INSURED_CANNOT_MATCH_AS_THERE_ARE_DIFFERENT_GENERAL_OR_DATE_OF_BIRTH,
        });
        clearTimeout(this.timeoutInsuredIsSame);
        this.timeoutInsuredIsSame = setTimeout(() => this.setState({ errorInsuredIsSame: '' }), 5000);
      }
    };

    getAllValues = (data) => {
      const {
        personBlockProps,
        documentBlockProps,
        addressBlockProps,
        bankBlockProps,
        addressLivingBlockProps,
        insSnilsBlockProps,
        contactBlockProps,
        relationBlockProps,
      } = this.props;

      return {
        ...personBlockProps.values,
        ...documentBlockProps.values,
        ...addressBlockProps.values,
        ...bankBlockProps.values,
        ...addressLivingBlockProps.values,
        ...insSnilsBlockProps.values,
        ...contactBlockProps.values,
        ...relationBlockProps.values,
        ...data.values,
      };
    };

    getAllValuesForAddress = this.customMemoize(this.getAllValues);

    getAllValuesForAddressLiving = this.customMemoize(this.getAllValues);

    getPayloadRequestCheckCode = () => {
      const { userInfo, personBlockProps, isInsured } = this.props;
      const inputPropsPersonBlock = this.getInputPropsPerson(personBlockProps);

      return {
        firstName: inputPropsPersonBlock.values.firstName,
        lastName: inputPropsPersonBlock.values.lastName,
        patronymic: inputPropsPersonBlock.values.patronymic,
        birthDate: simpleDate(inputPropsPersonBlock.values.birthDate),
        branchId: userInfo.branchId,
        partnerId: userInfo.partnerId,
        personType: isInsured ? 'insured' : 'insurer',
      };
    }

    render() {
      const {
        isLoading,
        snilsError,
        error: serverError,
        isInsured,
        insuredIsSame,
        hideInn,
        hideSnils,
        checkNumbers,
        personBlockProps,
        documentBlockProps,
        addressBlockProps,
        bankBlockProps,
        addressLivingBlockProps,
        insSnilsBlockProps,
        contactBlockProps,
        relationBlockProps,
        addressLivingBlockProps: {
          values: {
            livingAddressIsSame,
          },
        },
        isChild,
        isChildForm,
        setInsuredIsSame,
        scenario,
        disableInsuredIsSame,
        checkPassportCovid,
        insurerIsInsuredSetting,
        isListBlackAddress,
        hideBankInfo,
      } = this.props;
      const {
        isPerson,
        isDocuments,
        isAddress,
        isAddressLiving,
        isInnSnils,
        isContacts,
        isBank,
        ageError,
        errorInsuredIsSame,
        disableFIO,
        isDisableMobile,
      } = this.state;
      const isInnSnilsBlock = isInnSnils && (!hideInn || !hideSnils);
      const errorWarning = serverError || ageError;
      const isNsz = scenario.includes(INS_LINE_TYPES.NSZ);
      const isControlDisabled = (insurerIsInsuredSetting || isInsured) && isNsz;
      const { documentType } = documentBlockProps.values;
      const isRequiredEmailAndMobile = !isChildForm ||
        documentType !== DOCUMENT_TYPE_BIRTH_DOCUMENT;

      const inputPropsDocumentBlock = this.getInputPropsDocuments(documentBlockProps);
      const inputPropsAddressBlock = this.getInputPropsAddress(addressBlockProps);
      const inputPropsAddressLivingBlock = this.getInputPropsAddressLiving(
        addressLivingBlockProps,
      );

      return (
        <Form loading={isLoading}>
          <div className={b('formContainer', 'inputs')}>
            {
                isInsured && !isChildForm && (
                  <div className={formContainer('formSection')}>
                    <Form.Field className={b('addressCheckboxWrap').toString()}>
                      <CustomCheckBox
                        name="insuredIsSame"
                        className={b('addressCheckbox').toString()}
                        checked={insurerIsInsuredSetting || insuredIsSame}
                        isShowDisabled
                        onChange={() => setInsuredIsSame(!insuredIsSame)}
                        onClick={(...args) => !insurerIsInsuredSetting
                          && this.handleClickInsuredIsSame(...args)}
                        disabled={disableInsuredIsSame || insurerIsInsuredSetting}
                      />
                      <p>{IS_SAME}</p>
                    </Form.Field>
                    <Collapse isOpen={Boolean(errorInsuredIsSame)}>
                      <div className={b('insured-is-same-error')}>
                        <Icon name="warning" />
                        <p className={b('insured-is-same-error-text')}>{errorInsuredIsSame}</p>
                      </div>
                    </Collapse>
                  </div>
                )
            }
            <Collapse isOpen={this.isShow()}>
              <>
                {(insuredIsSame || !isChildForm) && <RequiredLabel />}
                {isInsured && isChild && <p className={b('form-title')}>{SINGULAR_INSURED}</p>}
                {isInsured && isChildForm && <p className={b('form-title')}>{SINGULAR_INSURED_CHILD}</p>}
                {
                  isInsured && (
                    <div className={formContainer('formSection')}>
                      <RelationShipBlock
                        className={b('relationship')}
                        inputProps={this.getInputPropsRelation(relationBlockProps)}
                        isChildForm={isChildForm}
                      />
                    </div>
                  )
                }
                {
                  isPerson && (
                    <PersonBlock
                      isInsured={isInsured}
                      inputProps={this.getInputPropsPerson(personBlockProps)}
                      validateFullName={this.validateTerrorist}
                      validateByDate={this.validateTerroristByDate}
                      disableBirthdate={isControlDisabled}
                      disableGender={isControlDisabled}
                      disableAutodetectGender={isControlDisabled}
                      isChildForm={isChildForm}
                      checkPassportCovid={checkPassportCovid}
                      disableFIO={disableFIO}
                    />
                  )
                }
                {
                  isDocuments && (
                    <FormSection
                      label={isChildForm ? DOCUMENTS_CHILD : DOCUMENTS}
                      inputs={(
                        <>
                          <DocumentBlock
                            selectSearch={this.setFmsUnit}
                            setFieldValue={this.customSetFieldValue(BLOCK_NAMES.documentBlock)}
                            inputProps={inputPropsDocumentBlock}
                            documentType={documentBlockProps.values.documentType}
                            onlyPassport={!isInsured}
                            isResetDataOnChangeDocumentData
                          />
                        </>
                      )}
                    />
                  )
                }
                {
                  isAddress && (
                    <FormSection
                      label={isChildForm ? REGISTRATION_ADDRESS_CHILD : REGISTRATION_ADDRESS}
                      inputs={(
                        <AddressBlockCommon
                          inputProps={{
                            ...this.getInputPropsAddress(addressBlockProps),
                            setValues: this.handleChangeAddress,
                          }}
                          isLivingAddress={false}
                          insuredIsSame={insuredIsSame}
                          isInsured={isInsured}
                          index={isChildForm ? 1 : 0}
                          isListBlackAddress={isListBlackAddress}
                          selectSearch={this.setAddress()}
                          insuredValues={this.getAllValuesForAddress(addressBlockProps)}
                          childInsuredValues={this.getAllValuesForAddress(addressBlockProps)}
                          isManualEnter={inputPropsAddressBlock.values.isManualEnter}
                          toggleTypeEnter={(value) => inputPropsAddressBlock.onChange({
                            target: {
                              name: 'isManualEnter',
                              value,
                            },
                          })}
                        />
                      )}
                    />
                  )
                }
                {
                  isAddressLiving && (
                    <FormSection
                      label={isChildForm ? LIVING_ADDRESS_CHILD : LIVING_ADDRESS}
                      isShow={!livingAddressIsSame}
                      inputs={(
                        <AddressBlockCommon
                          inputProps={{
                            ...inputPropsAddressLivingBlock,
                            setValues: this.handleChangeAddress,
                          }}
                          isLivingAddress
                          insuredIsSame={insuredIsSame}
                          isInsured={isInsured}
                          selectSearch={this.setAddress(true)}
                          isListBlackAddress={isListBlackAddress}
                          insuredValues={this.getAllValuesForAddressLiving(addressLivingBlockProps)}
                          index={isChildForm ? 1 : 0}
                          childInsuredValues={
                            this.getAllValuesForAddressLiving(addressLivingBlockProps)
                          }
                          isManualEnter={inputPropsAddressLivingBlock.values.isManualEnterLiving}
                          toggleTypeEnter={(value) => inputPropsAddressLivingBlock.onChange({
                            target: {
                              name: 'isManualEnterLiving',
                              value,
                            },
                          })}
                        />
                      )}
                      checkBoxButton={() => this.renderAddressCheckbox(
                        this.getInputPropsAddressLiving(addressLivingBlockProps),
                      )}
                    />
                  )
                }
                {
                  isInnSnilsBlock && (
                    <FormSection
                      inputs={InnSnilsBlock({
                        validateSnils: this.validateSnils,
                        inputProps: this.getInputPropsInnSnils(insSnilsBlockProps),
                        snilsError,
                        hideInn,
                        hideSnils,
                      })}
                    />
                  )
                }
                {
                  isContacts && (
                    <FormSection
                      label={isChildForm ? CONTACT_DATA_CHILD : CONTACT_DATA}
                      inputs={
                        ContactBlock({
                          inputProps: this.getInputPropsContact(contactBlockProps),
                          options: {
                            isRequiredEmail: isRequiredEmailAndMobile,
                            isRequiredMobile: isRequiredEmailAndMobile,
                            isDisableMobile,
                          },
                        })
}
                    />
                  )
                }
                {
                  isBank && !hideBankInfo && (
                    <FormSection
                      label={BANK_DETAILS}
                      inputs={(
                        <BankBlock
                          inputProps={this.getInputPropsBank(bankBlockProps)}
                          setFieldValue={this.customSetFieldValue(BLOCK_NAMES.bankBlock)}
                          selectSearch={this.setBankValues}
                          checkNumbers={checkNumbers}
                        />
                      )}
                    />
                  )
                }
              </>
            </Collapse>
            <ErrorMessage message={errorWarning} />
          </div>
        </Form>
      );
    }
  }

  AgentData.propTypes = propTypes;
  AgentData.defaultProps = defaultProps;

  function mapStateToProps(state) {
    const {
      authReducer: {
        clientTestRecordFields,
      },
    } = state;

    return {
      blackAddressInfo: getBlackAddressInfo(state),
      clientTestRecordFields,
    };
  }

  const mapDispatchToProps = (dispatch, ownProps) => ({
    GetError: bindActionCreators(getError, dispatch),
    ResetValidationTerrorist: bindActionCreators(resetValidationTerrorist, dispatch),
    checkNumbers: bindActionCreators(ownProps.checkNumbers, dispatch),
    saveData: bindActionCreators(ownProps.saveData, dispatch),
    changeFieldValue: bindActionCreators(changeAgentFieldValue, dispatch),
    validateFieldValues: bindActionCreators(validateAgentFieldValues, dispatch),
    setTouchField: bindActionCreators(setAgentTouchField, dispatch),
    setAgentType: bindActionCreators(setCurrentAgentType, dispatch),
    setInsuredIsSame: bindActionCreators(changeInsuredIsSame, dispatch),
    setAllTouchFields: bindActionCreators(setAgentAllTouchFields, dispatch),
    setStage: bindActionCreators(setStageProduct, dispatch),
    onChangeAddress: bindActionCreators(setAddressDataAgent, dispatch),
    SetInsuredValues: setInsuredValues,
  });

  return withCustomRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AgentData));
};

import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';
import { PopupContainer } from 'components';
import { TextInput } from 'components/_deprecated';
import { Button, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, MASK } from 'consts';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  createRecord,
  updateCreateRecord,
  checkUserNotBcs,
  checkProduct,
  getRecordFields,
  setClientTestingParams,
} from 'redux/actions';

import './styles.scss';
import { withCustomRouter } from 'HOC';
import { toastr } from 'react-redux-toastr';
import { sharedSelectors } from 'redux/rootSelectors';
import ResendTestLinkPopup from '../ResendTestLinkPopup';
import SuccessRecordExistsMessage from '../SuccessRecordExistsMessage';

const initialValues = {
  lastName: '',
  firstName: '',
  middleName: '',
  mobilePhone: '',
};

const b = block('recovery-login-modal');

const {
  CLIENT_TEST_CONTACT_FORM_TITLE,
  LAST_NAME,
  FIRST_NAME,
  PATRONYMIC,
  MOBILE_PHONE,
  INVALID_PHONE,
  SEND_SMS,
  SEND_SMS_AGAIN,
  REQUIRED,
  NO_MORE_TWENTY,
  CANCEL_WORD,
  ONLY_CYRILLIC_DASH,
  ONLY_CYRILLIC_DASH_AND_SPACES,
  ONLY_CYRILLIC_DASH_AND_BRACKETS,
} = LANG_DICTIONARY;

const propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  CreateRecord: PropTypes.func,
  UpdateCreateRecord: PropTypes.func,
  clientTestRecordId: PropTypes.number,
  isShowResendTestLinkPopup: PropTypes.bool,
  CheckUserNotBcs: PropTypes.func,
  CheckProduct: PropTypes.func,
  clientTestingProduct: PropTypes.object,
  isTestPassed: PropTypes.bool,
  GetRecordFields: PropTypes.func,
  nextTestDate: PropTypes.string,
  SetClientTestingParams: PropTypes.func,
};

const defaultProps = {
  isActive: false,
  className: '',
  CreateRecord: null,
  UpdateCreateRecord: null,
  clientTestRecordId: 0,
  isShowResendTestLinkPopup: false,
  CheckUserNotBcs: null,
  CheckProduct: null,
  clientTestingProduct: {},
  isTestPassed: false,
  GetRecordFields: null,
  SetClientTestingParams: null,
  nextTestDate: '',
};

const MAX_NAME_LENGTH = 50;
const { phoneMask } = MASK;

const validationSchema = yup.object().shape({
  lastName: yup
    .string()
    .matches(/^[а-яА-ЯёЁ\-()]+$/, ONLY_CYRILLIC_DASH_AND_BRACKETS)
    .max(MAX_NAME_LENGTH, NO_MORE_TWENTY)
    .required(REQUIRED),
  firstName: yup
    .string()
    .matches(/^[а-яА-ЯёЁ-]+$/, ONLY_CYRILLIC_DASH)
    .max(MAX_NAME_LENGTH, NO_MORE_TWENTY)
    .required(REQUIRED),
  middleName: yup.string().matches(/^[а-яА-ЯёЁ -]+$/, ONLY_CYRILLIC_DASH_AND_SPACES).max(MAX_NAME_LENGTH, NO_MORE_TWENTY),
  mobilePhone: yup.string().min(16, INVALID_PHONE).max(16, INVALID_PHONE).required(REQUIRED),
});

const ClientTestContactForm = ({
  isActive,
  onClose,
  className,
  CreateRecord,
  UpdateCreateRecord,
  clientTestRecordId,
  CheckUserNotBcs,
  CheckProduct,
  clientTestingProduct,
  isTestPassed,
  GetRecordFields,
  SetClientTestingParams,
  nextTestDate,
}) => {
  const getInputProps = (
    { name, placeholder },
    {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
    },
  ) => ({
    name,
    placeholder,
    values,
    errors,
    touched,
    onChange: handleChange,
    onBlur: (event) => {
      handleBlur(event);
      const { value } = event.target;
      switch (name) {
        case 'lastName':
        case 'firstName':
        case 'middleName':
          setFieldValue(name, value.trim());
          break;
        case 'mobilePhone':
          if (value.trim()[3] !== '9') setFieldValue(name, `+7(${value.trim().slice(4)}`);
          break;
        default:
          break;
      }
    },
  });

  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [formState, setFormState] = useState();
  const [isRecordExist, setIsRecordExist] = useState(false);
  const [isShowResendTest, setIsShowResendTest] = useState(false);
  const { masterId } = useSelector(sharedSelectors.getQueryParams);

  useEffect(() => {
    SetClientTestingParams({ clientTestRecordId: 0 });
    setIntervalId(null);
  }, []);

  useEffect(() => () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  }, [intervalId]);

  const sendSMS = (values, setSubmitting, isUpdate) => {
    if (isUpdate) {
      UpdateCreateRecord({ id: clientTestRecordId });
    } else {
      CreateRecord({ ...values, masterId });
    }
    setTimer(60);

    if (intervalId) {
      clearInterval(intervalId);
    }

    const newIntervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        }
        clearInterval(newIntervalId);
        setIntervalId(null);
        setSubmitting(false);
        SetClientTestingParams({ clientTestRecordId: 0 });

        return 0;
      });
    }, 1000);

    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    if ((formState) && (intervalId === null)) {
      if (clientTestRecordId > 0) {
        if (isTestPassed) {
          setIsRecordExist(true);
        } else if (nextTestDate === '') {
          setIsShowResendTest(true);
        } else {
          formState.setSubmitting(false);
        }
      } else if (clientTestRecordId < 0) {
        sendSMS(formState.values, formState.setSubmitting, false);
      }
    }
  }, [clientTestRecordId, nextTestDate]);

  const onOkResendLinkPopup = () => {
    if (formState) {
      sendSMS(formState.values, formState.setSubmitting, true);
      onCloseResendLinkPopup();
      formState.setSubmitting(true);
    }
  };

  const onCloseResendLinkPopup = () => {
    if (formState) {
      formState.setSubmitting(false);
    }
    setIsShowResendTest(false);
    SetClientTestingParams({ clientTestRecordId: 0 });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const valueList = { ...values, mobileNumber: values.mobilePhone.replace(/\D/g, '') };
    setFormState({ values: valueList, setSubmitting });
    CheckUserNotBcs({ ...valueList });
  };

  const onCloseClientTestRecordExist = () => {
    closeClientTestRecordExist();
    SetClientTestingParams({ clientTestRecordId: 0 });
  };

  const onOkClientTestRecordExist = () => {
    GetRecordFields({ id: clientTestRecordId });
    closeClientTestRecordExist();
    CheckProduct({ ...clientTestingProduct, toastr });
  };

  const closeClientTestRecordExist = () => {
    if (formState) {
      formState.setSubmitting(false);
    }
    setIsRecordExist(false);
    SetClientTestingParams({ clientTestRecordId: 0 });
  };

  return (
    <>
      <PopupContainer
        isOpen={isActive}
        className={className}
        close={onClose}
        isCloseClickOutside={false}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formData) => (
            <Form className={b('form').toString()}>
              <ResendTestLinkPopup
                isActive={isShowResendTest}
                onClose={onCloseResendLinkPopup}
                onOk={onOkResendLinkPopup}
                className={b()}
              />
              <SuccessRecordExistsMessage
                isActive={isRecordExist}
                onClose={onCloseClientTestRecordExist}
                onOk={onOkClientTestRecordExist}
                className={b()}
              />
              <div className={b('title')}>
                <p>{CLIENT_TEST_CONTACT_FORM_TITLE}</p>
              </div>
              <div className={b('form-container')}>
                <TextInput
                  {...getInputProps({ name: 'lastName', placeholder: LAST_NAME }, formData)}
                />
                <TextInput
                  {...getInputProps({ name: 'firstName', placeholder: FIRST_NAME }, formData)}
                />
                <TextInput
                  {...getInputProps({ name: 'middleName', placeholder: PATRONYMIC }, formData)}
                />
                <TextInput
                  {...getInputProps(
                    { name: 'mobilePhone', placeholder: MOBILE_PHONE },
                    formData,
                  )}
                  mask={phoneMask}
                />
              </div>
              <div className={b('controls')}>
                <Button
                  type="submit"
                  className={b('sendButton').toString()}
                  disabled={formData.isSubmitting}
                  onClick={formData.handleSubmit}
                >
                  { timer === 0 ? SEND_SMS : `${SEND_SMS_AGAIN} (${timer})` }
                </Button>
                <Button type="button" className={b('btn-cancel').toString()} onClick={onClose} disabled={formData.isSubmitting}>
                  {CANCEL_WORD}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </PopupContainer>
    </>
  );
};

ClientTestContactForm.propTypes = propTypes;
ClientTestContactForm.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  clientTestRecordId: state.authReducer.clientTestRecordId,
  isShowResendTestLinkPopup: state.authReducer.isShowResendTestLinkPopup,
  clientTestingProduct: state.authReducer.clientTestingProduct,
  isTestPassed: state.authReducer.isTestPassed,
  nextTestDate: state.authReducer.clientTestNextDate,
});

export default withCustomRouter(connect(
  mapStateToProps,
  {
    CreateRecord: createRecord,
    UpdateCreateRecord: updateCreateRecord,
    CheckUserNotBcs: checkUserNotBcs,
    CheckProduct: checkProduct,
    GetRecordFields: getRecordFields,
    SetClientTestingParams: setClientTestingParams,
  },
)(ClientTestContactForm));
